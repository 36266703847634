const CATALOG_NOT_FOUND_CODES = [400, 418]
import { ENABLE_LOCALIZATION } from '@/constants/featureFlags'

export const actions = {
  async nuxtServerInit({ dispatch }, { req, error, res }) {
    try {
      const host = getHost(req)
      if (process.env.NODE_ENV === 'development') {
        console.log(`Loading catalog: ${host}`)
      }
      const { school } = await dispatch('settings/fetch', {
        url: host,
      })
      const [_displayName, _departments, flags] = await Promise.all([
        dispatch('settings/fetchDisplayName', { school }),
        // TODO: We don’t necessarily need this during SSR
        //       Might reduce the request load
        // dispatch('reports/fetchRecentCatalogPdf', { school, catalogId }),
        dispatch('departments/fetch', { school }),
        this.$featureFlags.featureFlagsService.getAllFlags(school)
      ])

      dispatch('settings/initFlags', { flags })

      // Fetch and initialize localization if enabled
      if (flags[ENABLE_LOCALIZATION]) {
        try {
          const response = await this.$axios.$get(`/${school}/general/localizations`)
          const localizationSettings = response?.localizations?.[response.deafult || 'en-us']
          
          if (localizationSettings) {
            this.$updateLocalization({
              localizations: localizationSettings.localizations || {},
              dateFormat: localizationSettings.dateFormat || '',
              timeFormat: localizationSettings.timeFormat || ''
            })
          }
        } catch (error) {
          if (process.env.NODE_ENV === 'development') {
            console.warn(`Failed to fetch localizations for school ${school}:`, error)
          }
        }
      }
    } catch (err) {
      if (CATALOG_NOT_FOUND_CODES.includes(err.response?.status)) {
        console.error(err.response?.data?.error)
        error({
          statusCode: 404,
          message: 'Page not found',
          name: 'NO_CATALOG',
        })
      } else {
        this.$bugsnag.notify(err)
        throw err
      }
    }
  },
}

export function getHost(req) {
  // Use DEV_HOST if available
  if (process.env.DEV_HOST) return process.env.DEV_HOST
  if (process.env.NODE_ENV === 'development') {
    // parse cookie
    const cookieHost = decodeURIComponent(
      ((req.headers.cookie || '').match(/dev_selected_catalog=([^;]*)/) ||
        '')[1] || ''
    )
    // use cookie data if development and available
    if (cookieHost) {
      return cookieHost
    }
  }
  // fallback to req.host
  return req.headers.host
}
