import { computed } from '@vue/composition-api'
import { useContext } from '@nuxtjs/composition-api'
import { capitalize } from '@/utils/generalUtils'

export default function useMeta() {
  const { store, $localize } = useContext()

  const catalogWideMetaTitle = computed(() => {
    const school = store?.state?.settings?.school
    const schoolDisplayName = store?.state?.settings?.schoolDisplayName
    const catalogDisplayName = store?.state?.settings?.catalogDisplayName

    let baseTitle
    switch (school) {
      case 'stanford':
        baseTitle = `${schoolDisplayName} Bulletin`
        break
      case 'duke_peoplesoft':
        baseTitle = `${catalogDisplayName} Bulletin`
        break
      default:
        baseTitle = `${schoolDisplayName} ${capitalize($localize('catalog'))}`
    }

    return baseTitle
  })

  return {
    catalogWideMetaTitle,
  }
} 